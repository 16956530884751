import React from 'react';

import { nav, navItem, accented } from './user-desktop-navigation.module.scss';
import { INavLink } from '../../models/nav-link.model';
import Logout from '../../assets/images/svg/logout.svg';
import useTranslations from '../../hooks/use-translations';

import UserNavigationItem from '../atoms/user-navigation-item';

export interface IUserDesktopNavigationProps {
    className?: string;
    links: INavLink[];
    onLogout?: () => void;
}

export default function UserDesktopNavigation({
    className = '',
    links,
    onLogout,
}: IUserDesktopNavigationProps) {
    const t = useTranslations('UserNavigation');
    const filteredLinks = links.filter(
        (link) => link.type !== 'remoteUrl' && link.type !== 'phone'
    );

    return (
        <ul className={[nav, className].join(' ')}>
            {filteredLinks.map((link) => (
                <li key={`user-desktop-link-${link.linkId}`}>
                    <UserNavigationItem navLink={link} className={navItem} />
                </li>
            ))}
            <li>
                <UserNavigationItem className={[navItem, accented].join(' ')} onClick={onLogout}>
                    {t.logout} <Logout />
                </UserNavigationItem>
            </li>
        </ul>
    );
}
